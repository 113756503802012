<script setup>
const props = defineProps({
    to: {
        type: [String, Object],
        required: false,
        default: '',
    },
});

const makeFixedUri = computed(() => fixedUri(props.to));

</script>
<template>
    <NuxtLink
        class="Button inline-block rounded border border-carmin px-2 py-1 text-1xl font-light uppercase lg:text-base"
        :to="makeFixedUri"
    >
        <slot />
    </NuxtLink>
</template>
